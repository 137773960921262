<template>
	<div
		class="radiobuttongroup"
		:class="[size, { dark }]"
	>
		<TnLabel
			v-if="label"
			:for="uniqueId"
			:size="size"
			:dark="dark"
			>{{ label }}
		</TnLabel>
		<div
			class="radiobuttongroup--container"
			:class="[classes]"
			:id="uniqueId"
		>
			<TnRadiobutton
				v-for="(option, idx) in options"
				class="radiobutton"
				:key="`radiobutton-${idx}`"
				:label="option.label"
				:value="option.value"
				:name="name"
				:size="size"
				:selected="modelValue"
				:dark="dark"
				@change="handleChange"
			/>
		</div>
		<div class="support-text">
			<TnSupportText
				v-if="$slots['hint']"
				:size="size"
				:dark="dark"
			>
				<!-- @slot Slot for displaying hint-text (no icon) via TnSupportText -->
				<slot name="hint"></slot>
			</TnSupportText>

			<TnSupportText
				v-if="$slots['error']"
				critical
				:size="size"
				:dark="dark"
			>
				<!-- @slot Slot for displaying critical/error messages via TnSupportText -->
				<slot name="error"></slot>
			</TnSupportText>

			<TnSupportText
				v-if="$slots['warning']"
				warning
				:size="size"
				:dark="dark"
			>
				<!-- @slot Slot for displaying warning messages via TnSupportText -->
				<slot name="warning"></slot>
			</TnSupportText>

			<TnSupportText
				v-if="$slots['info']"
				info
				:size="size"
				:dark="dark"
			>
				<!-- @slot Slot for displaying informational messages via TnSupportText -->
				<slot name="info"></slot>
			</TnSupportText>

			<TnSupportText
				v-if="$slots['success']"
				success
				:size="size"
				:dark="dark"
			>
				<!-- @slot Slot for displaying success messages via TnSupportText -->
				<slot name="success"></slot>
			</TnSupportText>
		</div>
	</div>
</template>

<script>
export default defineComponent({
	name: "TnRadiobuttonGroup",
	props: {
		/**
		 * Array of options, with the keys `label` and `value`
		 */
		options: {
			type: Array,
			default: () => [],
		},
		/**
		 * Sets label of group
		 */
		label: {
			type: String,
			default: "",
		},
		/**
		 * Sets the size of TnRadioButtons and TnSupportText
		 * @values s, m, l
		 */
		size: {
			type: String,
			default: "m",
		},
		/**
		 * Displays TnRadioButtons inline
		 */
		inline: {
			type: Boolean,
			default: false,
		},
		/**
		 * Choose selected value
		 */
		modelValue: {
			type: String,
		},
		/**
		 * Sets the name of radiobuttons for forms
		 */
		name: {
			type: String,
		},
		/**
		 * Compact
		 */
		compact: {
			type: Boolean,
			default: false,
		},
		/**
		 * Dark theming
		 */
		dark: {
			type: Boolean,
			default: false,
		},
	},

	computed: {
		classes() {
			return {
				"radiobuttongroup--container--inline": this.inline,
				"radiobuttongroup--container--compact": this.compact,
				"radiobuttongroup--container--compact__inline": this.compact && this.inline,
			};
		},
		uniqueId() {
			return this.$attrs.id || this.$.uid;
		},
	},
	methods: {
		handleChange(event) {
			if (typeof event !== "object") {
				this.$emit("update:modelValue", event);
			}
		},
	},
});
</script>

<style lang="scss" scoped>
@use "@/assets/scss/variables" as variables;
@use "@/assets/typography/css/typography.css";

.radiobuttongroup {
	&--container {
		margin-top: 8px;
		margin-left: 8px;
		display: flex;
		flex-direction: column;

		&--inline {
			flex-direction: row;
			gap: 28px;
		}

		&--compact {
			.radiobutton {
				margin: -8px 0;

				&:first-of-type {
					margin-top: 0;
				}
			}

			&__inline {
				flex-direction: row;

				.radiobutton {
					margin: 0 -8px;

					&:first-of-type {
						margin-left: 0;
					}
				}
			}
		}
	}
}
</style>
